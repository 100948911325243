import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import LoginRedirect from '../shared-components/LoginRedirect'
import ListAssets from '../asset-management/ListAssets'
import Spinner from '../ui-elements/Spinner'
import Card from '../ui-elements/Card'
import Button from '../formfields/Button'
import TemperatureLogging from '../fridges/TemperatureLogging'
import { table } from 'console'
import SubNavigation from '../shared-components/SubNavigation'
import ChecklistDashboard from './ChecklistDashboard'
import DailyChecklistSummary from '../shared-components/DailyChecklistSummary'
import AssetsDashboard from './AssetsDashboard'
import ResultsDashboard from './ResultsDashboard'


type Props = {
}

function Dashboard({
}: Props) {

  const {
    tableData,
    loginUrl,
    activeFeatures,
    expiredFeatures,
    loggedIn,
    userData,
    currentOrganisation
  } = useContext(GlobalContext)

  function isMobileOrTablet() {
    //@ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const isSmallScreen = window.matchMedia('(max-width: 1024px)').matches;

    const isMobileAgent =
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

    return isMobileAgent || (isTouchDevice && isSmallScreen);
  }

  const roles_for_user = userData && userData['rolesForUser'] && JSON.parse(userData['rolesForUser'])
  const this_org_role_for_user = roles_for_user && roles_for_user[`${currentOrganisation}`]

  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>

      <h2
        data-testid={'heading-text'}
        className={`text-3xl  font-bold font-righteous font-brandblue`}
      >Dashboard</h2>

      {this_org_role_for_user === 'user' && <Card>
        <h3>Welcome to ChecQR</h3>
        <p>Your account does not have dashboard access for this organisation</p>

        {isMobileOrTablet() && <Button
          variant={'primary'}
          text={"Switch to checking webapp"}
          onClick={() => {
            window.location.href = 'https://mobile.checqr.net'
          }}></Button>}

      </Card>

      }

      {(!tableData || loggedIn === 'pending') && <Spinner><p>Loading...</p></Spinner>}

      {loggedIn === false && <LoginRedirect loginUrl={loginUrl} />}

      {loggedIn === true && tableData &&
        <div className={`w-full flex md:flex-row flex-col flex-wrap gap-4`}>
          <div className={`md:w-96 w-full flex flex-col flex-wrap gap-4`}>
          <ResultsDashboard />
          </div>

          <div className={`md:w-80 w-full flex flex-col flex-wrap gap-4`}>
            <ChecklistDashboard />
            <AssetsDashboard />
          </div>
        </div>
      }

    </div>
  </div>
}
export default Dashboard