import { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Spinner from "../ui-elements/Spinner"
import ListOfChecklists from '../checklists/ListOfChecklists'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import YellowAlert from '../ui-elements/YellowAlert'
import CheckPeriod from '../shared-components/CheckPeriod'
import { tab } from '@testing-library/user-event/dist/tab'
import { sortChecklistDataByTimePeriod } from '../checklists/checklist-utils/sortChecklistDataByTimePeriod'
import { getStartAndEndTimestampsForDay } from '../utils/getStartAndEndTimestampsForDay'

type Props = {
}


function ResultsDashboard({

}: Props) {

    const {
        tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket
    } = useContext(GlobalContext)

    const today = new Date()
    const day = today.getDate()
    const month = today.getMonth() + 1
    const year = today.getFullYear()
    const [checkPeriodsForThisDay, setCheckPeriodsForThisDay] = useState<ObjectStringKeyAnyValue | null>(null)

    useEffect(() => {
        if (tableData && !checkPeriodsForThisDay) {
            fetchData()
        }
        if (tableData && tableData['ChecklistPeriods'] && !checkPeriodsForThisDay) {
            filterChecklistPeriods()
        }

    }, [tableData, checkPeriodsForThisDay])


    const filterChecklistPeriods = () => {
        const newChecklistPeriodsByDay: ObjectStringKeyAnyValue = {}
        const timeZone = tableData.Organisation.OrganisationTimeZone || 'Europe/London'
        const dateString = `${year}-${`${month}`.padStart(2, '0')}-${`${day}`.padStart(2, '0')}`
        const { startTimestamp, endTimestamp } = getStartAndEndTimestampsForDay(dateString, timeZone)
        // console.log(`timestamps: ${startTimestamp} - ${endTimestamp}`)
        for (const key in tableData['ChecklistPeriods']) {
            const thisCheckPeriod = tableData['ChecklistPeriods'][key]
            if (
                parseInt(thisCheckPeriod['StartTimeStamp']) >= startTimestamp
                &&
                parseInt(thisCheckPeriod['StartTimeStamp']) <= endTimestamp
            ) {
                //console.log(`Found checklist for this day, saving with key ${key}`)
                newChecklistPeriodsByDay[key] = tableData['ChecklistPeriods'][key]
            }
        }
        setCheckPeriodsForThisDay(newChecklistPeriodsByDay)
    }

    const fetchData = () => {
        const payload = JSON.stringify({
            "action": "checklists",
            "subAction": "fetchChecklistInfoByTimePeriod",
            "month": month,
            "year": year,
            "day": day
        })
        // console.log('🎣 fetching...')
        sendMessageToWebsocket(payload)
    }


    return <Card fullwidth={true}>
        <div className={`w-full h-full flex flex-col gap-2 justify-between`}>

            <div className={`w-full flex flex-row itrems-center justify-between gap-2`}>
                <h3 className={`text-2xl font-righteous text-brandblue`}>Today's results</h3>
                <Button
                    internalLinkUrl={`/daily-checklist-summary`}
                    text={`All results`}
                    fullwidth={false}
                />
            </div>

            {(checkPeriodsForThisDay && Object.keys(checkPeriodsForThisDay).length > 0) ?
                <div className={`w-full flex flex-col flex-wrap gap-2`}>
                    {Object.keys(tableData['ChecklistPeriods']).map((checkPeriodId: string, index: number) => {
                        const thisCheckPeriod = checkPeriodsForThisDay[checkPeriodId]
                        if (!thisCheckPeriod) {
                            return null
                        }
                        return <CheckPeriod key={index} thisCheckPeriod={thisCheckPeriod} />
                    })}
                </div>
                :
                <p>No check periods</p>
            }

        </div>
    </Card>
}
export default ResultsDashboard