
type Props = {
    thisCheckPeriod: ObjectStringKeyAnyValue
}

function FormatChecklistTimeWindow({
    thisCheckPeriod
}: Props) {

  if (!thisCheckPeriod || !thisCheckPeriod.StartHoursMinsDay || !thisCheckPeriod.EndHoursMins) {
    return null
  }

  return  <>
  {` ${`${thisCheckPeriod.StartHoursMinsDay.hours}`.padStart(2, '0')}`}:
  {`${thisCheckPeriod.StartHoursMinsDay.minutes}`.padStart(2, '0')} - {`${thisCheckPeriod.EndHoursMins.hours}`.padStart(2, '0')}:
  {`${`${thisCheckPeriod.EndHoursMins.minutes}`.padStart(2, '0')} `}
</>
}
export default FormatChecklistTimeWindow